/** @jsx jsx */
import { jsx, Themed } from "theme-ui";
import Layout from "../components/Layout";
import RichText from "../components/RichText";
import CardGrid, { mapArticle } from "../components/CardGrid";
import { DESKTOP_ARTICLE_MAX_WIDTH } from "../utils/Constants";

import PhysicalIcon from "../assets/icons/physical.svg";
import MeaningfulIcon from "../assets/icons/meaningful.svg";
import JoyfulIcon from "../assets/icons/joyful.svg";
import IterativeIcon from "../assets/icons/iterative.svg";
import SocialIcon from "../assets/icons/social.svg";

import { GoodToKnow } from "../assets/images/brailleheaders";

const CharacteristicIconMap = {
  engagement: PhysicalIcon,
  meaningful: MeaningfulIcon,
  joyful: JoyfulIcon,
  iterative: IterativeIcon,
  social: SocialIcon,
};

const CharacteristicColorMap = {
  engagement: "lbbBlue",
  meaningful: "lbbRed",
  joyful: "lbbPurple",
  iterative: "lbbYellow",
  social: "lbbGreen",
};

const Facilitation = ({
  pageContext: {
    facilitationPage: { title, introduction, characteristics, seo },
    facilitationArticles,
  },
}) => {
  return (
    <Layout
      title={seo.title || title}
      description={seo.description}
      brailleTextImage={GoodToKnow}
    >
      <Themed.h1 sx={{ marginBottom: "35px" }}>{title}</Themed.h1>
      <div
        sx={{
          display: "flex",
          flexDirection: ["column", null, "row"],
          paddingBottom: "4rem",
        }}
      >
        <div
          sx={{
            flex: 1,
            maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
            paddingRight: [0, null, "1rem"],
            marginRight: "auto",
          }}
        >
          <RichText render={introduction} />
        </div>
        <div
          sx={{
            flex: 1,
            marginTop: "1rem",
            maxWidth: [null, null, `${DESKTOP_ARTICLE_MAX_WIDTH}px`],
            marginRight: "auto",
          }}
        >
          {characteristics.map(({ id, title, body }, index) => (
            <div
              key={id}
              sx={{
                display: "flex",
                flexDirection: "row",
                marginBottom: index < characteristics.length - 1 ? "2rem" : 0,
              }}
            >
              <div>
                <div
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "60px",
                    height: "60px",
                    borderRadius: "30px",
                    bg: CharacteristicColorMap[id],
                    marginRight: "2rem",
                  }}
                >
                  <img src={CharacteristicIconMap[id]} alt=""></img>
                </div>
              </div>
              <div>
                <Themed.h3
                  sx={{
                    variant: "text.h6",
                    display: "inline",
                    fontWeight: "bold",
                  }}
                >
                  {title}
                </Themed.h3>
                <Themed.p
                  sx={{ display: "inline", margin: 0 }}
                >{` - ${body}`}</Themed.p>
              </div>
            </div>
          ))}
        </div>
      </div>
      <Themed.h2
        sx={{
          variant: "text.h1",
          fontWeight: "bold",
        }}
      >
        Braille materials and tools
      </Themed.h2>
      <CardGrid
        items={facilitationArticles.map(article =>
          mapArticle({
            ...article,
            linkTo: `/facilitation/${article.uid}`,
          })
        )}
      />
    </Layout>
  );
};

export default Facilitation;
